/**
 * represents an entry from the alertModals section in the CMS
 * @param {element} - the html element node for the modal, the outermost div in templates/_alerts/_modal.twig
 * @param {function} onContinue - the callback function to be executed when the user clicks the CTA to continue
 * @param {function} onDismiss - callback function to be executed when the modal is dismissed from the close icon 
 */
class AlertModal {
	constructor(element, onContinue = () => {}, onDismiss = () => {}) {
		this.root = element;
		this.onContinue = onContinue;
		this.onDismiss = onDismiss;
		this.setupDismissButton();
		this.setupContinueButtons();
	}
	/**
	 * attaches event listener to the dismiss button if modal is dismissible
	 */
	setupDismissButton() {
		this.dismissButton = this.root.querySelector(".closeModal");
		if (!this.dismissButton) {
			return;
		}
		this.dismissButton.addEventListener("click", () => {
			this.hide();
			this.onDismiss();
		});
	}
	/**
	 * attaches event listener to the continue buttons
	 */
	setupContinueButtons() {
		this.continueButtons = this.root.querySelectorAll("button.continue");
		if (!this.continueButtons) {
			return;
		}
		this.continueButtons.forEach((button) => {
			button.addEventListener("click", (e) => this.onContinue(e));
		});
	}
	/**
	 * shows the modal
	 */
	show() {
		this.root.classList.remove("hidden");
	}
	/**
	 * hides the modal
	 */
	hide() {
		this.root.classList.add("hidden");
	}
}

export default AlertModal;
